<template>
	<v-radio-group
		dense
		mandatory
		:class="customClass"
		id="id"
		v-model="radioinput"
		:row="row"
		color="blue darken-4"
		v-on:change="$emit('change', radioinput)"
		:hide-details="hideDetails"
	>
		<v-radio
			v-for="(item, index) in items"
			:readonly="readonly"
			:disabled="disabled"
			:key="index"
			color="blue darken-4"
			:label="item.label"
			:value="item.value"
		></v-radio>
	</v-radio-group>
</template>
<script>
export default {
	name: "radio-input",
	title: "Radio Input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Boolean, Number, String],
			default: null,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		id: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: "mt-3 pt-0",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		row: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.radioinput = param;
			},
		},
	},
	data() {
		return {
			radioinput: null,
		};
	},
	mounted() {
		this.radioinput = this.value;
	},
};
</script>
