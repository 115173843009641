<template>
	<v-sheet class="invoice" id="invoice" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="orange darken-3"
					tile
					:disabled="pageLoading"
					v-on:click="updateOrCreate('save')"
				>
					Save
				</v-btn>
				<v-btn
					v-if="!is_invoice_sent"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateOrCreate('save_and_send')"
				>
					Save &amp; Send</v-btn
				>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form ref="invoiceForm" v-model.trim="formValid" lazy-validation>
					<div style="max-width: 768px">
						<table width="100%" style="table-layout: fixed">
							<tr>
								<td width="30%">
									<label for="asset" class="btx-label mt-2 required">Asset</label>
								</td>
								<td width="70%">
									<AutoCompleteInput
										hide-details
										:items="assetList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="asset"
										item-text="name"
										item-value="id"
										placeholder="Asset"
										v-model="invoice.asset"
										:rules="[vrules.required(invoice.asset, 'Asset')]"
										:class="{ required: !invoice.asset }"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td width="30%">
									<label for="service" class="btx-label mt-2 required">Service</label>
								</td>
								<td width="70%">
									<AutoCompleteInput
										hide-details
										:items="serviceList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="service"
										item-text="service_type_formatted"
										item-value="id"
										placeholder="Service"
										v-model="invoice.service"
										:rules="[vrules.required(invoice.service, 'Service')]"
										:class="{ required: !invoice.service }"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td width="30%">
									<label for="asset-id" class="btx-label mt-2 required">Asset ID</label>
								</td>
								<td width="70%">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="asset-id"
										placeholder="Asset ID"
										v-model="invoice.asset_id"
										:rules="[vrules.required(invoice.asset_id, 'Asset ID')]"
										:class="{ required: !invoice.asset_id }"
									></TextInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="date" class="btx-label mt-2 required">Invoice Date</label>
								</td>
								<td>
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="date"
										placeholder="Invoice Date"
										v-model="invoice.date"
										:rules="[vrules.required(invoice.date, 'Invoice Date')]"
										:class="{ required: !invoice.date }"
									></DatePicker>
								</td>
							</tr>
							<tr>
								<td>
									<label for="service-type" class="btx-label mt-2 required">Service Type</label>
								</td>
								<td>
									<AutoCompleteInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="service-type"
										placeholder="Service Type"
										v-model="invoice.service_type"
										:items="serviceTypeList"
										:rules="[vrules.required(invoice.service_type, 'Service Type')]"
										:class="{ required: !invoice.service_type }"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="service-performed-by" class="btx-label mt-2 required"
										>Service Performed by</label
									>
								</td>
								<td>
									<RadioInput
										row
										hide-details
										v-model="invoice.performed_by"
										:disabled="pageLoading"
										id="service-performed-by"
										:items="performedItems"
										:rules="[vrules.required(invoice.performed_by, 'Service Performed by')]"
										:class="{ required: !invoice.performed_by }"
									></RadioInput>
								</td>
							</tr>
							<tr v-if="invoice.performed_by == 1">
								<td>
									<label for="engineer" class="btx-label mt-2 required">Engineer</label>
								</td>
								<td>
									<AutoCompleteInput
										hide-details
										:items="engineerList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="engineer"
										placeholder="Engineer"
										v-model="invoice.engineer"
										:rules="[vrules.required(invoice.engineer, 'Engineer')]"
										:class="{ required: !invoice.engineer }"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr v-else>
								<td>
									<label for="subcon" class="btx-label mt-2 required">Subcon</label>
								</td>
								<td>
									<AutoCompleteInput
										hide-details
										:items="subconList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="subcon"
										placeholder="Subcon"
										v-model="invoice.subcon"
										:rules="[vrules.required(invoice.subcon, 'Subcon')]"
										:class="{ required: !invoice.subcon }"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="invoice-id" class="btx-label mt-2 required">Invoice Number</label>
								</td>
								<td>
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="invoice-id"
										placeholder="Invoice Number"
										v-model="invoice.invoice_id"
										:rules="[vrules.required(invoice.invoice_id, 'Invoice Number')]"
										:class="{ required: !invoice.invoice_id }"
									></TextInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="invoice-amount" class="btx-label mt-2 required">Invoice Amount</label>
								</td>
								<td>
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="invoice-amount"
										placeholder="Invoice Amount"
										v-model="invoice.total_amount"
										:rules="[vrules.required(invoice.total_amount, 'Invoice Amount')]"
										:class="{ required: !invoice.total_amount }"
									></PriceInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="remark" class="btx-label mt-2">Remarks</label>
								</td>
								<td>
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:rows="2"
										id="remark"
										placeholder="Remarks"
										v-model="invoice.remark"
									></TextAreaInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="attachments" class="btx-label mt-2">Attachments</label>
								</td>
								<td>
									<FileUpload v-model="invoice.attachment"></FileUpload>
								</td>
							</tr>
						</table>
					</div>
				</v-form>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
// import { findIndex, toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import DatePicker from "@/view/components/DatePicker";
import RadioInput from "@/view/components/RadioInput";
import FileUpload from "@/view/components/FileUpload";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import PriceInput from "@/view/components/PriceInput";
import ObjectPath from "object-path";

export default {
	name: "invoice-create",
	title: "Create Invoice",
	data() {
		return {
			uuid: null,
			serviceUUID: null,
			cloneUUID: null,
			barcode: null,
			formValid: true,
			pageLoading: false,
			updateInvoice: false,
			assetList: [],
			serviceList: [],
			engineerList: [],
			subconList: [],
			invoice: {
				asset: null,
				service: null,
				asset_id: null,
				date: null,
				service_type: null,
				performed_by: null,
				engineer: null,
				total_amount: 0,
				subcon: null,
				invoice_id: null,
				remark: null,
				attachment: null,
			},
			is_invoice_sent: false,
			serviceTypeList: [
				{
					text: "On Site",
					value: "on-site",
				},
				{
					text: "In Lab",
					value: "in-lab",
				},
			],
			performedItems: [
				{
					label: "Engineer",
					value: 1,
				},
				{
					label: "Subcon",
					value: 2,
				},
			],
		};
	},
	components: {
		TextInput,
		DatePicker,
		PriceInput,
		RadioInput,
		FileUpload,
		TextAreaInput,
		AutoCompleteInput,
	},
	methods: {
		async updateOrCreate(action) {
			try {
				const _this = this;

				const formErrors = _this.validateForm(_this.$refs.invoiceForm);

				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.invoiceForm.validate()) {
					return false;
				}

				this.pageLoading = true;

				let requestURL = "invoice";

				if (this.uuid) {
					requestURL = `invoice/${this.uuid}`;
				}

				const formData = new FormData();

				formData.append("action", action);
				formData.append("asset", this.invoice.asset);
				formData.append("service", this.invoice.service);
				formData.append("asset_id", this.invoice.asset_id);
				formData.append("date", this.invoice.date);
				formData.append("service_type", this.invoice.service_type);
				formData.append("performed_by", this.invoice.performed_by);
				formData.append("total_amount", this.invoice.total_amount);
				if (this.invoice.performed_by == 1) {
					formData.append("engineer", this.invoice.engineer);
					formData.append("subcon", 0);
				} else {
					formData.append("subcon", this.invoice.subcon);
					formData.append("engineer", 0);
				}
				formData.append("invoice_id", this.invoice.invoice_id);
				if (this.invoice.remark) {
					formData.append("remark", this.invoice.remark);
				}

				if (this.invoice.attachment && this.invoice.attachment.length) {
					for (let i = 0; i < this.invoice.attachment.length; i++) {
						if (this.invoice.attachment[i].file) {
							formData.append(`attachments[${i}][file]`, this.invoice.attachment[i].file);
							formData.append(`attachments[${i}][name]`, this.invoice.attachment[i].name);
							formData.append(`attachments[${i}][suffix]`, this.invoice.attachment[i].name);
						}
					}
				}

				ApiService.upload(requestURL, formData)
					.then(() => {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Invoice has been saved." },
						]);

						_this.$router.replace({
							name: "invoice",
							query: { t: new Date().getTime() },
						});
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			} catch (error) {
				this.logError(error);
			}
		},
		initInvoice() {
			this.pageLoading = true;
			ApiService.query("invoice/option", this.invoice)
				.then((output) => {
					this.assetList = ObjectPath.get(output, "data.assets");
					this.serviceList = ObjectPath.get(output, "data.services");
					if (!this.uuid) {
						this.invoice.asset = ObjectPath.get(output, "data.asset.id");
						this.invoice.service = ObjectPath.get(output, "data.service.id");
						this.invoice.asset_id = ObjectPath.get(output, "data.asset.id_number");
						this.invoice.date = ObjectPath.get(output, "data.asset.");
						this.invoice.service_type = ObjectPath.get(output, "data.service.service_type");
						this.invoice.performed_by = ObjectPath.get(output, "data.service.service_by");
						this.invoice.engineer = ObjectPath.get(output, "data.service.supplier");
						this.invoice.subcon = ObjectPath.get(output, "data.service.member");
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getInvoice() {
			ApiService.get(`invoice/${this.uuid}`)
				.then((output) => {
					this.barcode = ObjectPath.get(output, "data.barcode");
					this.invoice.asset = ObjectPath.get(output, "data.asset");
					this.invoice.service = ObjectPath.get(output, "data.service");
					this.invoice.asset_id = ObjectPath.get(output, "data.asset_id");
					this.invoice.date = ObjectPath.get(output, "data.date");
					this.invoice.service_type = ObjectPath.get(output, "data.service_type");
					this.invoice.performed_by = ObjectPath.get(output, "data.performed_by");
					this.invoice.total_amount = ObjectPath.get(output, "data.total_amount");
					this.invoice.engineer = ObjectPath.get(output, "data.engineer.id", 0);
					this.invoice.subcon = ObjectPath.get(output, "data.subcon.id", 0);
					this.invoice.invoice_id = ObjectPath.get(output, "data.invoice_id");
					this.invoice.remark = ObjectPath.get(output, "data.remark");
					this.is_invoice_sent = !!ObjectPath.get(output, "data.is_sent_alcon", 0);
					const is_approved = !!ObjectPath.get(output, "data.is_approved", 0);
					if (is_approved) {
						this.goBack();
					}
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Invoice", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCloneInvoice() {
			ApiService.get(`invoice/${this.cloneUUID}`)
				.then((output) => {
					this.invoice.asset = ObjectPath.get(output, "data.asset");
					this.invoice.service = ObjectPath.get(output, "data.service");
					this.invoice.asset_id = ObjectPath.get(output, "data.asset_id");
					this.invoice.date = ObjectPath.get(output, "data.date");
					this.invoice.service_type = ObjectPath.get(output, "data.service_type");
					this.invoice.performed_by = ObjectPath.get(output, "data.performed_by");
					this.invoice.total_amount = ObjectPath.get(output, "data.total_amount");
					this.invoice.engineer = ObjectPath.get(output, "data.engineer.id", 0);
					this.invoice.subcon = ObjectPath.get(output, "data.subcon.id", 0);
					this.invoice.invoice_id = ObjectPath.get(output, "data.invoice_id");
					this.invoice.remark = ObjectPath.get(output, "data.remark");
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Invoice - " + this.barcode;
			}
			return "Create new Invoice";
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Invoice", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;

		if (name === "invoice-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getInvoice();
		}

		if (this.uuid) {
			this.updateInvoice = true;
		}

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");
		this.invoice.asset = ObjectPath.get(this.$route, "query.asset");
		this.invoice.service = ObjectPath.get(this.$route, "query.service");

		if (this.cloneUUID) {
			this.getCloneInvoice();
		}

		this.initInvoice();

		this.engineerList = this.localDB("engineers", []);
		this.subconList = this.localDB("subcons", []);
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
